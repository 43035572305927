.anim {
  animation-name: opacity;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  opacity: 0.2;
}
@keyframes opacity {
  0%{
    opacity: 0.1;
  }
  20%{
    opacity: 0.2;
  }
  30%{
    opacity: 0.3;
  }
  40%{
    opacity: 0.4;
  }
  50%{
    opacity: 0.5;
  }
  60%{
    opacity: 0.6;
  }
  60%{
    opacity: 0.7;
  }
  80%{
    opacity: 0.8;
  }
  90%{
    opacity: 0.9;
  }
  100%{
    opacity: 0.21;
  }
}
