@keyframes ldio-x5q6u8nqww-o {
    0% { opacity: 1; 
        transform: translate(0 0); }
    49.99% {opacity: 1; 
        transform: translate(80px,0); }
  50%    {opacity: 0;
     transform: translate(80px,0); }
  100%    {opacity: 0;
     transform: translate(0,0); }
}
  @keyframes ldio-x5q6u8nqww {
    0% { transform: translate(0, 0) ;}
50% {transform: translate(80px,0) ;}
  100% {transform: translate(0,0); }
}
  .ldio-x5q6u8nqww div {
    position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  top: 60px;
  left: 20px;
}
  .ldio-x5q6u8nqww div:nth-child(1) {
    background: #fcb600;
  animation: ldio-x5q6u8nqww 1s linear infinite;
  animation-delay: -0.5s;
}
  .ldio-x5q6u8nqww div:nth-child(2) {
    background: #000000;
  animation: ldio-x5q6u8nqww 1s linear infinite;
  animation-delay: 0s;
}
  .ldio-x5q6u8nqww div:nth-child(3) {
    background: #fcb600;
  animation: ldio-x5q6u8nqww-o 1s linear infinite;
  animation-delay: -0.5s;
}
  .loadingio-spinner-dual-ball-dl1gubbe7se {
    width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: #f1f2f3;
}
  .ldio-x5q6u8nqww {
    width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
  .ldio-x5q6u8nqww div {box-sizing: content-box; }
/* generated by https://loading.io/ */